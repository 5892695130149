import React from "react";
import { Link, useLocation } from "react-router-dom";
import aryologo from "../images/aryologowhite.png";
import mail from "../images/mail.png";
import call from "../images/call_logo_new.png";
import badge from "../images/googleplay_badge.png";
import instagram from "../images/instagram.png";
import telegram from "../images/telegram.png";
import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";
import location_logo from "../images/location_2.png";
import "../css/footer.css";

const products = [
  "Savings Account",
  "Demat Account",
  "Business Account",
  "Current Account",
  "Credit Card",
  "Buy Now Pay Later",
  "Investment Accounts",
];

function Footer() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();

  function goToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function goToProduct() {
    if (location.pathname.includes("become-a-dsa")) {
      if (window.innerWidth < 768) {
        window.scrollTo({
          top: 2700,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 1700,
          behavior: "smooth",
        });
      }
    } else {
      if (window.innerWidth < 768) {
        window.scrollTo({
          top: 1700,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 1100,
          behavior: "smooth",
        });
      }
    }
  }

  return (
    <div className="footer-wrapper">
      <div className="footer-container-one">
        <div className="footer-box">
          <div className="footer-products">
            <p>Products</p>
            <ul>
              {products.map((product, index) => (
                <li key={index} onClick={() => goToProduct()}>
                  <div className="product_link">{product}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_company_info">
            <div className="footer-company">
              <p>Company</p>
              <ul>
                <li onClick={goToTop}>
                  {" "}
                  <Link to="" id="contact_link" onClick={goToTop}>
                    Home
                  </Link>
                </li>
                <li>About us</li>
              </ul>
            </div>
            <div className="footer-contact">
              <p>Contact Us</p>
              <ul>
                <li id="phoneNumber">
                  <span>
                    <img src={call} alt="" id="call-logo" />
                  </span>
                  <span> </span>
                  &nbsp;&nbsp;
                  <a href="tel:+919310611604" id="call">
                    +91 9310611604
                  </a>
                </li>
                <li id="emailid">
                  <span>
                    <img src={mail} alt="" id="mail-logo" />
                  </span>
                  &nbsp; &nbsp;
                  <a href="mailto:contactus@aryo.club" id="mail">
                    contactus@aryo.club
                  </a>
                </li>
                <li id="address">
                  <div>
                    <img src={location_logo} alt="location" id="address_logo" />
                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div id="address_info">
                    <p>
                    Office No. 129-30 Westend Mall Janakpuri West New Delhi 110058</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="advertise">
          <div>
            {" "}
            <Link to="/" onClick={goToTop}>
              <img src={aryologo} alt="" id="aryologo" />
            </Link>
            <p>
              <span className="rupee-icon"></span> Sell financial products and
              earn ₹1 Lakh/month
            </p>
            <Link
              to="https://play.google.com/store/apps/details?id=com.aryo.aryoleads&referrer=utm_source%3Daryo-website%26utm_term%3Dwebsite%26utm_campaign%3Dwebsite"
              className="media_link"
              target="_blank"
            >
              <img src={badge} alt="" id="badge" />{" "}
            </Link>
            <p>Follow Us</p>
            <ul id="social-media">
              <li>
                <Link
                  to="https://t.me/ARYOmessages"
                  className="media_link"
                  target="_blank"
                >
                  <img src={telegram} alt="" />
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.instagram.com/invites/contact/?i=1l3lwel8a4bmh&utm_content=noje1dw"
                  className="media_link"
                  target="_blank"
                >
                  {" "}
                  <img src={instagram} alt="" />{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="https://m.facebook.com/www.aryo.club"
                  className="media_link"
                  target="_blank"
                >
                  {" "}
                  <img src={facebook} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/aryofintech/"
                  className="media_link"
                  target="_blank"
                >
                  <img src={linkedin} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-container-two">
        <ul>
          <li>
            <Link
              to="/terms"
              className="tc"
              onClick={() => {
                goToTop();
              }}
            >
              Terms & Conditions
            </Link>
          </li>
          <li>
            {" "}
            <Link
              to="/privacy"
              className="tc"
              onClick={() => {
                goToTop();
              }}
            >
              {" "}
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul>
          <p id="copyright">
            All Rights Reserved |{" "}
            <span>
              <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
            </span>{" "}
            <span id="companyname">@ARYO&nbsp;</span>
            <span>{currentYear}&nbsp;</span>
          </p>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
