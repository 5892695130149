import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../images/profile.png";
import kyc_logo2 from "../images/kyc_logo_2.png";
import aryologo from "../images/aryologowhite.png";
import bank from "../images/bank_logo.png";
import "../css/account.css";
import "../css/navbar.css";
import { auth, getMKyc } from "../Firebase";


function Navbar2() {
  // console.log("current user ", auth.currentUser.uid);
 
  useEffect(() => {
    getMKyc( kyc => {
      // console.log("isKyc Done ", kyc);
      if (kyc === undefined) {
        localStorage.setItem('mkyc', false);
      } else {
        localStorage.setItem('mkyc', kyc);
      }
      
    })
    
  }, [])

  // if (agentProfile !== null)
  {

    return (
      <>
        <div className="navbar-wrapper">
          <div className="company_info">
            <div>
              <Link to="" className="linkPath-logo">
                <img src={aryologo} alt="" />
              </Link>
            </div>
          </div>

        </div>
        <div className="account_header">
          <div className="acc_heading">
            <p>Profile</p>
          </div>
        </div>
        <div className="account_wrapper">
          <div className="other_details">
            <Link to="profile" className="update-container">
              <div className="update-details">
                <img src={user} alt="" />
                <div>
                  <p> Personal Details</p>
                  <p>Edit Details</p>
                </div>
              </div>
            </Link>
            <Link to="kyc" className="update-container">
              <div className="update-details">
                <img src={kyc_logo2} alt="" />
                <div>
                  <p> KYC Details</p>
                  <p>KYC Documents</p>
                </div>
              </div>
              {/* <div className="update-container_button">
                <button>Start KYC</button>
              </div> */}
            </Link>
            {/* <Link to="updatecompanydetails" className="update-container">
              <div className="update-details">
                <img src={company_logo} alt="" />
                <div>
                  <p> Company Details</p>
                  <p>Company Documents</p>
                </div>
              </div>
            </Link> */}
            <Link to="bankDetails" className="update-container">
              <div className="update-details">
                <img src={bank} alt="" />
                <div>
                  <p> Bank Details</p>
                  <p>Bank Documents</p>
                </div>
              </div>
            </Link>

          </div>
        </div>
      </>
    );

  }
  
}

export default Navbar2;
