import React, { useEffect, useState } from "react";
import UpdatedMessageModal from "./UpdatedMessageModal";
import Error from "./Error";
import { checkPincode } from "../Connection";
import { auth, updateFirebaseEmail, updateProfile } from "../Firebase";
import Spinner from "./Spinner";
import * as Validation from "./Validations";
// import { SUCCESS, FAILURE } from "./States";
import fbutton from "../images/forward_arrow.png";
import * as States from "./States";
import dropdown from "../images/dropdown.png";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { fetchProfile } from "../Firebase";
import "../css/companydetails.css";
import "../css/style.css";
import { Timestamp } from "@firebase/firestore";

function PersonalDetails() {

  // console.log("mobile ", localStorage.getItem('mobile'))
  const navigate = useNavigate();

  const [updateEmailInFirebase, setUpdateEmailInFirebase] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const [formData, setFormData] = useState({
    name: "",
    mobile: '',
    emailId: "",
    gender: "",
    pinCode: "",
    state: "",
  });

  // const [formData, setFormData] = useState({
  //     name: profile !== null ? profile.name : '',
  //     mobile: mobile,
  //     emailId: profile !== null ? profile.emailId : '',
  //     gender: profile !== null ? profile.gender : '',
  //     pinCode: profile !== null ? profile.pinCode : '',
  //     state: profile !== null ? profile.state : '',
  // });

  const [formErrors, setFormErrors] = useState({
    name: "",
    mobile: "",
    emailId: "",
    gender: "",
    pinCode: "",
    state: "",
    interestedCategory: [],
  });

  // const [state, setState] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (updateStatus === States.SUBMITTED && updateEmailInFirebase) {
      navigate('/details')
    }
  }, [updateStatus])

  useEffect(() => {
    if (!loading && user === null) {
      navigate("/login");
    }
  }, [loading, user]);

  // useEffect(() => {
  //   if (state !== "") {
  //     setFormData({ ...formData, ["state"]: state });
  //   }
  // }, [state]);

  useEffect(() => {
    if (user !== null) {
      fetchProfile((profile) => {
        // console.log("profile ", profile)
        if (profile !== null) {
          setFormData({
            name: profile.name,
            mobile: profile.mobile,
            emailId: profile.emailId,
            gender: profile.gender,
            pinCode: profile.pinCode,
            state: profile.state,
          });
        } else {
          setUpdateEmailInFirebase(true);
          if (localStorage.getItem("mobile") === undefined) {
            navigate("/login");
          } else {
            setFormData({
              ...formData,
              ['mobile']: localStorage.getItem("mobile"),
            });
          }
        }
      });
    }
  }, [user]);

  // console.log(localStorage.getItem("mobile"));

  const onInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, " => ", value);
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  const closeModal = () => {
    setShowmodal(false);
    navigate('/details');

  };
  const closeError = () => {
    setShowError(false);
    navigate('/details');
  };

  const submitPersonalDetails = () => {
    setIsFormSubmitted(true);

    const newFormErrors = {
      name: Validation.validateFullName(formData.name),
      emailId: Validation.validateEmail(formData.emailId),
      pinCode: formData.pinCode.length !== 6 ? "Invalid Pincode" : "",
      state: formData.state === "" ? "Invalid State" : "",
      gender: ["Male", "Female"].includes(formData.gender) ? "" : "Select Gender"
  };
    // console.log("formError ", formErrors);
    setFormErrors(newFormErrors);

    if (
      newFormErrors.name.length === 0 &&
      newFormErrors.emailId.length === 0 &&
      newFormErrors.pinCode.length === 0 &&
      newFormErrors.state.length === 0 &&
      newFormErrors.gender.length === 0
    ) {
      setUpdateStatus(States.INIT);
      document.body.style.overflowY = "hidden";

      // console.log("should update email ", updateEmailInFirebase, " mobile ", formData.mobile);
      if (updateEmailInFirebase) {
        const fEmail = `${formData.mobile.toString()}_aryo@aryo.club`;
        updateFirebaseEmail(
          fEmail,
          (result) => {
            // console.log("updateFirebaseEmail ", result);
            if (result) {
              formData.createdOn = Timestamp.now();
              formData.isDSA = true;
              updateProfile(formData, (response) => {
                // console.log("isUpdated ", response);
                setUpdateStatus(States.SUBMITTED);
                response ? setShowmodal(true) : setShowError(true);
              });
            } else {
              navigate("/login");
            }
          }
        );
      } else {
        updateProfile(formData, (response) => {
          if (response) { 
            setUpdateStatus(States.SUBMITTED);
            setShowmodal(true);
           }else {
            setShowError(true);
           } 
          document.body.style.overflowY = "scroll";
        });
      }
    }
  };

  const setSelectedGender = (gender) => {
    setFormData({ ...formData, ["gender"]: gender });
    setIsOpen(false);
  };

  const verifyPincode = (e) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: '' });
    
    // Update form data first
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
  
    // Check if the value is a valid 6-digit pincode
    if (/^\d{6}$/.test(value)) {
      checkPincode(value, (res) => {
        // console.log(res)
        if (res.valid) {
          // Update state with the response data
          setFormData((prevFormData) => ({
            ...prevFormData,
            state: res.state
          }));
          setFormErrors({ ...formErrors, [name]: '' });
        }
        else{
          setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        }
      });
    }
  };
  
  // console.log("redner personal details ", formData);
  if (!loading && user !== null) {
    return (
      <>
        {showError && <Error closeError={closeError} />}
        {showModal && <UpdatedMessageModal closeModal={closeModal} />}
        {updateStatus === States.INIT && (
          <div className="center-screen">
            <Spinner />
          </div>
        )}
        {/* {showBankDetailsForm && (<BankDetails name={props.personalData.name}/>)} */}
        <div className="company_details-wrapper" id="pd-profile">
          <div className="profile_box">
            <div className="comapny-details-form">
              <div className="details_heading">
                <p>Update Personal Details</p>
              </div>

              <div className="detail_input_label">
                <p>Name (As Per PAN Card)</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="name"
                  maxLength={25}
                  onChange={onInputChange}
                  defaultValue={formData.name}
                />
                {isFormSubmitted && formErrors.name && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span> {formErrors.name}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>Mobile</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="mobile"
                  maxLength={10}
                  readOnly={true}
                  onChange={onInputChange}
                  defaultValue={formData.mobile}
                />
                {isFormSubmitted && formErrors.mobile && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.mobile}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>Email</p>
              </div>
              <div className="detail_input">
                <input
                  type="email"
                  name="emailId"
                  maxLength={100}
                  onChange={onInputChange}
                  defaultValue={formData.emailId}
                />
                {isFormSubmitted && formErrors.emailId && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.emailId}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>Gender</p>
              </div>
              <div className="dropdown" onClick={toggleDropdown}>
                <button className="dropbtn">
                  <span>
                    {formData.gender === "" ? "Select" : formData.gender}
                  </span>
                  <img
                    style={{ height: "15px", width: "15px" }}
                    src={dropdown}
                    alt="drpdn"
                  />
                </button>
                {isOpen && (
                <div className="dropdown-content">
                  <p
                    className="drpitm"
                    onClick={() => setSelectedGender("Male")}
                  >
                    Male
                  </p>
                  <p
                    className="drpitm"
                    onClick={() => setSelectedGender("Female")}
                  >
                    Female
                  </p>
                </div>
                 )}
              </div>
              {isFormSubmitted && formErrors.gender && (
                <div className="error-message" id="namingerror">
                  <span className="error-star"></span> {formErrors.gender}
                </div>
              )}

              <div className="detail_input_label">
                <p>Pincode</p>
              </div>
              <div className="detail_input">
                <input
                  type="tel"
                  id="pinCode"
                  name="pinCode"
                  required="required"
                  maxLength={6}
                  value={formData.pinCode}
                  onChange={verifyPincode}
                />
                {isFormSubmitted && formErrors.pinCode && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.pinCode}
                  </div>
                )}
                {!isFormSubmitted && formErrors.pinCode && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.pinCode}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>State</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="state"
                  maxLength={6}
                  onChange={onInputChange}
                  value={formData.state}
                />
                {isFormSubmitted && formErrors.state && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.state}
                  </div>
                )}
              </div>

              <div
                className="bank_detail_button"
                onClick={submitPersonalDetails}
              >
                <button>UPDATE</button>
                <img src={fbutton} alt="" id="fbutton" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Spinner />;
  }
}

export default PersonalDetails;
